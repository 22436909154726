import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Row, Container, Spinner } from "reactstrap";
import logo from "../../assets/images/LogoHighRes.png";
import date from "moment";

const Success = (props) => {
	const url = localStorage.getItem("redirectUrl");
	const time_p4 = localStorage.getItem('time_p4')
	let diff = date.now() - time_p4
	// const url = null
	const proceed = localStorage.getItem("proceed");
	localStorage.clear();
	sessionStorage.clear()
	window.setTimeout(function() {
		if (url && proceed && diff < 5*60*1000){
			window.location.replace(url)
		} else {
			window.location.replace("https://portal.kalpayfinancials.com")
		}
	}, 5000);

	return (
		<React.Fragment>
			<div>
				<Container fluid={true}>
					<div className="text-center mt-5">
						<img src={logo} alt="" width={200} />
					</div>
					<Row className="justify-content-center mt-4">
						<div className="text-center mt-5">
							<h1>Thank you! Your order has been placed</h1>

							<h6 style={{ marginTop: "2%", fontSize: 32, fontWeight: "400" }}>
								You will soon get a verification call from our team. Please wait while you're being re-directed to the homepage
							</h6>
							<a style={{ cursor: "pointer" }} href={url}>
								<p style={{ color: "navy" }}>Click here to redirect</p>
							</a>
							<div
								className="container"
								style={{
									display: "flex",
									justifyContent: "center",
									minHeight: "35vh",
									alignItems: "center",
								}}
							>
								<Spinner color="danger" size="md" />
							</div>
						</div>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withRouter(Success);