import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Container, Spinner } from "reactstrap";
import logo from "../../assets/images/LogoHighRes.png";

const SuccessInstallment = (props) => {
    const url = "https://portal.kalpayfinancials.com"

    window.setTimeout(function() {
        window.location.replace(url)
    }, 5000);
    return (
        <React.Fragment>
            <div>
                <Container fluid={true}>
                    <div className="text-center mt-5">
                        <img src={logo} alt="" width={200} />
                    </div>
                    <Row className="justify-content-center mt-4">
                        <div className="text-center mt-5">
                            <h1>Thank you for using KalPay. Your installment is being processed, it can take up to 10 minutes for the changes to reflect in your account. </h1>

                            <h6 style={{ marginTop: "2%", fontSize: 32, fontWeight: "400" }}>
                                Please wait while you're being re-directed to the homepage
                            </h6>
                            <a style={{ cursor: "pointer" }} href={url}>
                                <p style={{ color: "navy" }}>Click here to re-direct</p>
                            </a>
                            <div
                                className="container"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    minHeight: "35vh",
                                    alignItems: "center",
                                }}
                            >
                                <Spinner color="danger" size="md" />
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(SuccessInstallment);
