import React from 'react';
import { Link } from "react-router-dom";

const NavigationButton = (props) => {
    const onClickHandler = () => {
        props.apiCall(props.activeTab)
    }
    return (
        <ul
            className="pager wizard twitter-bs-wizard-pager-link d-flex justify-content-center"
        >
            <li
                className={
                    props.activeTab === 5 ? "next disabled" || "Submit" : "next"
                }
            >
                {
                    <Link className="link-style"
                        to="#"
                        onClick={onClickHandler}
                    >
                        {props.activeTab === 4 ? "Pay Now" : "Next"}
                    </Link>
                }
            </li>
        </ul>
    )
}
export default NavigationButton;
