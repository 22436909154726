import { unix } from "moment";
import { Row, Col } from "reactstrap";

const PaymentSummary = (props) => {

  return (
    <Row>
      <Col>
        {
          props.orderDetails.installmentBreakDown.map((item, index) =>
            <div class="simplebar-content" key={index}>
              <ul class="list-unstyled activity-wid">
                <li class="activity-list" >
                  <div class="activity-icon avatar-xs">
                    <span
                      class="avatar-title rounded-circle payment-installment"
                    >
                      <p
                        class="payment-detail"
                      >
                        {index + 1}
                      </p>
                    </span>
                  </div>

                  <div>
                    <div className="d-flex justify-content-between">
                      <div>
                        {unix(item.date / 1000).format("D MMM")}
                      </div>
                      <div>
                        <h5 class="font-size-15">
                          Installment {index + 1} of {props.orderDetails.totalInstallments ? props.orderDetails.totalInstallments : 3}<br />
                          <small class="text-dark">
                            {unix(item.date / 1000).format("dddd, Do, MMMM, YYYY")}
                          </small>
                        </h5>
                      </div>

                      <div>
                        <p class="text-dark mb-0">
                          <strong>
                            Rs.{" "}
                            {item.amount}
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )
        }
      </Col>
    </Row>

  )
}
export default PaymentSummary;