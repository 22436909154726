import React from 'react'

const Footer = () => {
    return (
        <React.Fragment>
            <div className="text-center">
                <a href="https://mail.google.com/mail/u/0/#inbox?compose=DmwnWsdKPsMbxJgZnjQXGRvgDRnBGctJLNjNSXlgxJZFcdQtgDqhbnvttPLHttwdFdCNvfLCxgXV" target="_blank">
                    Report a problem
                </a>
            </div>
            <div className="text-center mt-4">
                2022 KalPay - All rights reserved.
            </div>
        </React.Fragment>
    )
}
export default Footer;
