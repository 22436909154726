import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Row, Container, Spinner } from "reactstrap";
import logo from "../../assets/images/LogoHighRes.png";

const PayMobCompletion = (props) => {
    const params = Object.fromEntries(new URLSearchParams(props.location.search))
    let redirectURL = 'https://portal.kalpayfinancials.com'
    const orderInfo = params.merchant_order_id?.split('|')
    if (params.success === "true" && orderInfo.length===3){
        if (orderInfo[2] === 'isOrder'){
           //
           redirectURL = '/complete'
        } else {
            redirectURL = '/installment'
        }
    } else {
        redirectURL = '/failure'
    }
    window.setTimeout(function() {
        window.location.replace(redirectURL)
    }, 2000);

    return (
        <React.Fragment>
            <div>
                <Container fluid={true}>
                    <div className="text-center mt-5">
                        <img src={logo} alt="" width={200} />
                    </div>
                    <Row className="justify-content-center mt-4">
                        <div className="text-center mt-5">
                            {/*<h1>Please wait, we are processing your Payment. </h1>*/}

                            {/*<a style={{ cursor: "pointer" }} href={redirectURL}>*/}
                            {/*    <p style={{ color: "navy" }}>Click here to redirect</p>*/}
                            {/*</a>*/}
                            <div
                                className="container"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    minHeight: "35vh",
                                    alignItems: "center",
                                }}
                            >
                                <Spinner color="danger" size="md" />
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(PayMobCompletion);