import React from 'react';
import { Col } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import {errorMessages, placeHolders, regexCollection} from "../../utilities/Util";

const EnterPhone = (props) => {
    const onChangeHandler = (e) => {
        props.setUserDetails({...props.userDetails, phone:(e.target.value)});
    }

    return (
        <Col sm="12" md="12" lg="12">
            <div className="mb-3">
                <AvField
                    name="phone"
                    className="phone-input"
                    placeholder={placeHolders.phone}
                    type="text"
                    errorMessage={errorMessages.phone}
                    value={props.userDetails["phone"]}
                    maxLength="11"
                    required
                    validate={{
                        required: true,
                        pattern: { value: regexCollection.phone },
                    }}
                    onChange={onChangeHandler}
                />
            </div>
            {/*<h4 className='error-message text-left'>*/}
            {/*    {props.error}*/}
            {/*</h4>*/}
        </Col>
    )
}

export default EnterPhone;