import {apolloClient, GET_ACTIVE_USER_ORDERS} from "./GQLServices";
import {configURLs} from "../config/utils";
import {errorMessages} from "../utilities/Util";

export const URLParser = async (splitting, raw_params) => {
    let parsed_info = {};
    let last_accessed = "-1";
    let items = {};
    if (raw_params?.includes('0[wc_key]') || splitting.source === '1') {
        for (let value in splitting) {
            if (value[0] === last_accessed) {
                //	this indicates that it's not a woocom
                continue;
            }
            let items = {};
            last_accessed = value[0]
            if (value[0] === '0') {
                parsed_info['first_name'] = splitting["0[customer][first_name]"].trim();
                parsed_info['last_name'] = splitting["0[customer][last_name]"].trim();
                parsed_info['address'] = splitting["0[customer][address]"].trim();
                parsed_info['city'] = splitting["0[customer][city]"].trim();
                parsed_info['email'] = splitting["0[customer][email]"].trim();
                parsed_info['phone'] = splitting["0[customer][phone]"].trim();
                parsed_info['amount_without_delivery'] = parseFloat(splitting["0[amount]"]);
                parsed_info['delivery_charges'] = parseFloat(splitting["0[delivery_charges]"].trim());
                parsed_info['merchant_id'] = splitting["0[merchant_id]"].trim();
                parsed_info['order_id'] = splitting["0[order_id]"].trim();
                parsed_info['merchant_name'] = splitting["0[merchant_name]"].trim();
                parsed_info['source'] = splitting["0[source]"].trim();
                parsed_info['items'] = []
                parsed_info['callback'] = splitting["0[callback]"].trim();
                sessionStorage.setItem('wckey', splitting["0[wc_key]"])
                sessionStorage.setItem('wcsecret', splitting["0[wc_secret]"])
                sessionStorage.setItem('serverCallBack', `${parsed_info['callback']}/wp-json/wc/v3/orders/${parsed_info['order_id']}/?consumer_key=${splitting["0[wc_key]"]}&consumer_secret=${splitting["0[wc_secret]"]}&status=processing`);
                // parsed_info['callback'] = configURLs.baseFallBackReDirectionURL
            }
            items.url = splitting[`${value[0]}[url]`]
            items.quantity = splitting[`${value[0]}[quantity]`]
            items.name = splitting[`${value[0]}[name]`]
            items.category = "Category";
            items.price = splitting[`${value[0]}[price]`];
            parsed_info['items'].push(items)
        }
    } else if (splitting.source === '0') {
        // this indicates shopify product
        items.name = 'Shopify Product'
        items.category = "Category";
        items.quantity = 1;
        splitting.amount_without_delivery = parseFloat(splitting['amount_without_delivery'])
        splitting.delivery_charges = parseFloat(splitting['delivery_charges'])
        items.price = splitting.amount_without_delivery + splitting.delivery_charges
        splitting.items = [items]
        parsed_info = splitting
        sessionStorage.setItem('serverCallBack', `${parsed_info['callback']}`);
        // parsed_info['callback'] = configURLs.baseFallBackReDirectionURL
    //    here

    } else {
        splitting.amount_without_delivery = parseFloat(splitting['amount_without_delivery'])?parseFloat(splitting['amount_without_delivery']):0
        splitting.delivery_charges = parseFloat(splitting['delivery_charges'])?parseFloat(splitting['delivery_charges']):0
        splitting['items'] = splitting['items']?JSON.parse(splitting['items']):[{name: "Custom Product",
            category: "Unknown", quantity: 1, "price": splitting.amount_without_delivery + splitting.delivery_charges}]
        parsed_info = splitting
        sessionStorage.setItem('serverCallBack', `${parsed_info['serverCallback'] ? parsed_info['serverCallback'] : parsed_info['callback']?parsed_info['callback']:configURLs.baseFallBackReDirectionURL}`);
    }

    // setting of states

    sessionStorage.setItem("storeOrderId", parsed_info['order_id']?parsed_info['order_id']:null);
    localStorage.setItem("redirectUrl", parsed_info['callback']?parsed_info['callback']:configURLs.baseFallBackReDirectionURL);
    sessionStorage.setItem("source", parsed_info["source"]?parsed_info["source"]:3)
    parsed_info['orderTotal'] = parsed_info.amount_without_delivery + parsed_info.delivery_charges
    return parsed_info;
}

export const uploadImageClickHandler = async (mutate, frontImageSrc, backImageSrc, userDetails, setUserDetails,
                                              setCnicFrontSidePath, setCnicBackSidePath, setCnicUploaded, setError,
                                              setShowPopupError, userDetailsEdit, setUserDetailsEdit) => {
    try {
        let response = await mutate({
            variables: {
                cnicFront: frontImageSrc,
                cnicBack: backImageSrc,
                phone: userDetails['phone']
            },
        })
        let validMessage = response?.data?.uploadCNIC
        if (validMessage?.['valid']) {
            let extractedDetails = response?.data?.uploadCNIC?.details
            setUserDetailsEdit({
                ...userDetailsEdit,
                cnic: !extractedDetails['identity_number'],
                dateOfBirth: !extractedDetails['date_of_birth'],
                dateOfIssue: !extractedDetails['date_of_issue'],
                dateOfExpiry: !extractedDetails['date_of_expiry']
            })
            if (!extractedDetails['gender'] && extractedDetails['identity_number']) {
                let lastDigit = extractedDetails['identity_number'][extractedDetails['identity_number'].length - 1];
                if (parseInt(lastDigit) % 2 === 0) {
                    extractedDetails['gender'] = "FEMALE"
                } else {
                    extractedDetails['gender'] = "MALE"
                }
            }
            setUserDetails({
                ...userDetails, cardType: extractedDetails?.card_type || "",
                dateOfBirth: extractedDetails?.date_of_birth?.replaceAll('.', "/") || "",
                dateOfExpiry: extractedDetails?.date_of_expiry?.replaceAll('.', "/") || "",
                dateOfIssue: extractedDetails?.date_of_issue?.replaceAll('.', "/") || "",
                husbandName: extractedDetails?.husband_name || "",
                gender: extractedDetails?.gender || "",
                cnic: extractedDetails?.identity_number || "",
                firstName: extractedDetails?.name?.split(" ").slice(0, extractedDetails?.name.split(" ").length - 1).join(" ") || "",
                lastName: extractedDetails?.name?.split(" ")[extractedDetails?.name.split(" ").length - 1]  || ""
            })
            setCnicFrontSidePath(response?.data?.uploadCNIC?.cnicFrontSidePath)
            setCnicBackSidePath(response?.data?.uploadCNIC?.cnicBackSidePath)
            // console.log(data?.data?.uploadCNIC?.details?.identity_number.replace(/-/g, ""))
            setCnicUploaded(true)
            return true
        } else {
            setShowPopupError(true);
            setError(validMessage.message ? validMessage.message : "Please Try again")
            return false
        }
    } catch (error){
        setShowPopupError(true);
        setError(errorMessages.internalError)
        return false
    }
}
export const signUpFields = async (userDetails, setError, setLoading) => {
    if (userDetails["firstName"].length < 1) {
        setError("Please enter your First Name");
        setLoading(false);
        return false;
    } else if (userDetails["lastName"].length < 1) {
        setError("Please enter your Last Name");
        setLoading(false);
        return false;
    } else if (userDetails["motherName"].length < 1) {
        setError("Please enter your Mother Name");
        setLoading(false);
        return false;
    }
    else if (userDetails["cnic"].length < 1) {
        setError("Please enter your CNIC");
        setLoading(false);
        return false;
    } else if (userDetails["email"].length < 1) {
        setError("Email is required");
        setLoading(false);
        return false;
    } else if (userDetails["address"].length < 1) {
        setError("Please enter your Address");
        setLoading(false);
        return false;
    } else if (userDetails["city"].length < 1) {
        setError("Please enter your City");
        setLoading(false);
        return false;
    } else if (userDetails["requiredCheckbox"] !== "on") {
    setError("Please accept terms and conditions");
    setLoading(false);
    return false;
}
    return true
}

export const validatorUser = async (userInfo, setorderErrorReason, setallowOrder, setcreditLimit, orderTotal) => {
    let activeOrders = await apolloClient.query({ query: GET_ACTIVE_USER_ORDERS })
    if (!((userInfo['active_order_limit'] - activeOrders?.['data']['getActiveOrdersOfUser']['count']) > 0)) {
        setorderErrorReason("Active order limit reached")
        setallowOrder(false)
    }
    const totalCreditLimit = parseFloat(userInfo['base_credit_limit']?.amount) + parseFloat(userInfo['extra_credit_limit']?userInfo['extra_credit_limit']:0)
    setcreditLimit(totalCreditLimit)
    if (orderTotal > totalCreditLimit) {
        setorderErrorReason("Order total more than allowed credit limit")
        setallowOrder(false)
    }
}

export const formatDateToYYYYMMDD = (date)=>{
    if (!date || date === '')
        return '';

    let separator = '';

    if (date.includes('.'))
        separator = '.';

    else if (date.includes('/'))
        separator = '/';

    else if (date.includes('-'))
        separator = '-';

    const [day, month, year] = date.split(separator);

    return year + '-' + month + '-' + day; // ISO 8601 format
}

// export const cardFieldsValidate = async (state) => {
//     let expiry_month = parseFloat(state.expiryDate?.substring(0, 2));
//     let expiry_year = parseFloat("20"+state.expiryDate?.substring(3, 5));
//
//     const currentDate = moment()
//     if ((expiry_year===currentDate.year() && expiry_month>currentDate.month()) || (expiry_year<currentDate.year())){
//         return false
//     }
//     if (!isCreditCard(state.cardNumber)){
//         return false
//     }
//     return true
// }