import OtpInput from "react-otp-input";
import {Row, Col} from "reactstrap";
import {OTPService} from "../../services/GQLCommands";

const Otp = (props) => {

    const onChangeHandler = (e) => {
        if (e.length === 4) {
            {props.apiCall(2, e)}
        }
        {props.setCode(e)}
    }

    const resendOtpHandler = () => {
        {props.setTimeLeft(60)};
        OTPService(props.phone.phone);
    }

    return (
        <div>
            <Row className="d-flex align-items-center justify-content-center">
                <div className="mb-3 mt-4">
                    <h4 className="font-style">Enter the code sent to {props.phone.phone}</h4>
                </div>
            </Row>
            <div className="d-flex align-items-center justify-content-center row">
                <Col lg="12">
                    <div className="mb-3 d-flex justify-content-center">
                        <OtpInput
                            // inputStyle="otp-input"
                            inputStyle={{ width: "100%", margin: "5px", height: "55px", radius: "10px", left: "520px", padding: "20px", borderTop: 0, borderRight: 0, borderLeft: 0, fontSize: "18px"}}
                            value={props.code}
                            onChange={onChangeHandler}
                            numInputs={4}
                            separator={<p>  {"  "}</p>}
                        />
                    </div>

                    <div className="font-style">
                        OTP will expire in {props.timeLeft} seconds.{" "}
                        <span className='error-message'>
                            <a
                                className="cursor-style"
                                onClick={resendOtpHandler}
                            >
                                Resend OTP
                            </a>
                        </span>
                    </div>
                    <h4 className='error-message'>
                        {props.error}
                    </h4>
                </Col>
                <Col sm="0" md="3" lg="3"></Col>
            </div>
        </div>
    )

}

export default Otp;