import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {Row, Container, Spinner, Modal, ModalHeader, ModalBody,} from "reactstrap";
import logo from "../../assets/images/LogoHighRes.png";

const PayFast = (props) => {
	const [loading, setLoading] = useState(false);
	const [showPopup, setShowPopup] = useState(false);

	localStorage.setItem('proceed', true);

	useEffect(async () => {
		document.getElementById("paymentButton").click();
	}, []);

	const payNow = async () => {
		console.log("Submitting form ", props.location.state.body);
		document.getElementById("paymentButton").click();
	};
	const closeBtn = (
		<button className="close secondary" onClick={() => setShowPopup(false)}>
			&times;
		</button>
	);
	return (
		<React.Fragment>
			<div>
				<Container fluid={true}>
					<div className="text-center mt-5">
						<img src={logo} alt="" width={200} />
					</div>
					<div
						className="container"
						style={{
							display: "flex",
							justifyContent: "center",
							minHeight: "35vh",
							alignItems: "center",
						}}
					>
						<Spinner color="danger" size="md" />
					</div>
					<Row className="justify-content-center mt-4">
						<form
							method="post"
							// action="https://ipguat.apps.net.pk/Ecommerce/api/Transaction/PostTransaction"

							action="https://ipg1.apps.net.pk/Ecommerce/api/Transaction/PostTransaction"
						>
							<input type={"hidden"} name="MERCHANT_ID" value="4414" />
							<input
								type={"hidden"}
								name="MERCHANT_NAME"
								value="KalPay Technologies Private Limited"
							/>
							<input
								type={"hidden"}
								name="TOKEN"
								value={props.location.state.body.token}
							/>
							<input
								type={"hidden"}
								readonly="readonly"
								name="PROCCODE"
								value={props.location.state.body.procode}
							/>
							<input
								type={"hidden"}
								name="TXNAMT"
								value={props.location.state.body.txnamt}
							/>
							<input
								type={"hidden"}
								name="CUSTOMER_MOBILE_NO"
								value={props.location.state.body.customer_mobile_number}
							/>
							<input
								type={"hidden"}
								name="CUSTOMER_EMAIL_ADDRESS"
								value={props.location.state.body.customer_email_address}
							/>
							<input
								type={"hidden"}
								name="SIGNATURE"
								v-model="signature"
								value="KalPayFinancials"
							/>
							<input type={"hidden"} name="VERSION" value="MY_VER_1.0" />
							<input type={"hidden"} name="TXNDESC" value="KalPay" />
							<input
								type={"hidden"}
								name="SUCCESS_URL"
								value={props.location.state.body.successUrl}
							/>
							<input
								type={"hidden"}
								name="FAILURE_URL"
								value={props.location.state.body.failureUrl}
							/>
							<input
								type={"hidden"}
								name="BASKET_ID"
								value={props.location.state.body.basket_id}
							/>
							<input
								type={"hidden"}
								name="ORDER_DATE"
								value={props.location.state.body.order_date}
							/>
							<input
								type={"hidden"}
								name="CHECKOUT_URL"
								value={props.location.state.body.checkout_url}
							/>
							<input
								type="submit"
								style={{ display: "none" }}
								id="paymentButton"
								class="float-right"
								TYPE="SUBMIT"
							/>
						</form>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withRouter(PayFast);
