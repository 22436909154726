export const validatePhoneNumber = (phone) => {
    if (phone.length < 1) {
        return "Phone number should not be empty"
    }
    if (phone.length < 11) {
        return "Phone number should have 11 digits";
    }
    if (phone[0] !== "0" || phone[1] !== "3") {
        return "Please enter phone number starting with 03."
    }
    if (phone.match(/[a-z]/i)) {
        return "only digits are allowed"
    }
    if (phone.includes("-")) {
        return "Phone number can only be digits."
    }
    if (phone.includes(" ")) {
        return "Phone number cannot have spaces. Please enter number without spaces."
    }
}

export const regexCollection = {
    'phone': '^((03)([0-9]{9}))$',
    'cnic': '^((?!0)([0-9]{5}[-][0-9]{7}[-][0-9]))$',
    'cardNumber': '(([0-9]{16}))$',
    'cvv': '(([0-9]{3}))$',
    'expiry': "^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^([l|L]{1}[i|I]{1}[f|F]{1}[e|E]{1}[T|t]{1}[i|I]{1}[M|m]{1}[E|e]{1})$"
}

export const errorMessages = {
    phone: "Number must start with 03 and should be 11 digits long",
    firstName: "Enter your first name",
    lastName: "Enter your last name",
    motherName: "Enter your Mother name",
    email: "Enter your email address",
    cnic: "CNIC must not start with 0 and should be 13 characters long",
    dateOfBirth: "Enter Your Date of Birth",
    dateOfIssue: "Enter Your CNIC Card's Date of Issue",
    dateOfExpiry: "Enter Your CNIC Card's Date of Expiry",
    address: "Enter your address",
    city: "Enter your city",
    invalidURL: 'Issue in URL forwarding, please contact KalPay for more information.', //  if there's an issue in the URL
    incompleteURL:'Incomplete Information from the Merchant.', // if amount without delivery or merchant ID is missing
    belowMinimumOrderLimit: 'Order Value below the minimum allowed value for this merchant.',
    unknownStore: "Merchant is not registered with KalPay.",
    orderCreationIssueGQL: "Error processing your order. Please contact KalPay for further information.",
    internalError: "Internal Error, contact KalPay",
    NOT_MODIFIED_304_SIGNUP: "CNIC or Email already exists.",
    USER_IS_MERCHANT: "You are already signed up as a merchant. Please contact KalPay for further assistance to place an order."

}

export const placeHolders = {
    phone: "03xx xxxxxxx",
    firstName: "First Name",
    lastName: "Last Name",
    motherName: "Mother Name",
    email: "abc@example.com",
    cnic: "xxxxx-xxxxxxx-x",
    date: "DD/MM/YYYY",
    address: "House #, Street #, City, Country",
    city: "City",

}