import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Row, Container, Spinner } from "reactstrap";
import logo from "../../assets/images/LogoHighRes.png";

const Failure = (props) => {
	return (
		<React.Fragment>
			<div>
				<Container fluid={true}>
					<div className="text-center mt-5">
						<img src={logo} alt="" width={200} />
					</div>
					<Row className="justify-content-center mt-4">
						<div className="text-center mt-5">
							<h1>Unfortunately, your transaction could not be processed. </h1>

							<h6 style={{ marginTop: "2%", fontSize: 32, fontWeight: "400" }}>
								Please contact your bank or try again.
							</h6>
							<div
								className="container"
								style={{
									display: "flex",
									justifyContent: "center",
									minHeight: "35vh",
									alignItems: "center",
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="red"
									 className="bi bi-exclamation-circle" viewBox="0 0 16 16">
									<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
									<path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
								</svg>
							</div>
						</div>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withRouter(Failure);
