import { gql, ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import { createUploadLink } from 'apollo-upload-client';
import {configURLs} from "../config/utils";

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = sessionStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

export const uploadLink = createUploadLink({ uri: configURLs.baseurl });

export const apolloClient = new ApolloClient({
    link: authLink.concat(uploadLink),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only'
        },
        mutate: {
            fetchPolicy: 'network-only'
        }
    }
});

export const STORE_ID = gql`
query GetStore ($id: String!) {
    getStoreV1(id: $id) {
        minimum_amount
        installment_options {
            id
            days
            cycle
            name
        }
    }
}`

export const ADD_ORDER = gql`
mutation AddOrder ($order: InputOrderV1!) {
  addOrderV1(order: $order) {
    id
  }
}
`

export const UPDATE_ORDER = gql`
mutation UpdateOrder ($id: String!, $order: UpdateOrderV1!) {
    updateOrderV1 (id: $id, order: $order)
}
`

export const CREATE_AUTH_CODE = gql`
mutation CreateAuthCode($phone: String!) {
  createAuthCode(phoneNumber: $phone)
}
`

export const VALIDATE_AUTH_SIGN = gql`
mutation ValidateAuthCodeAndSignIn($authCode: String!, $phoneNumber: String, $email: String) {
    validateAuthCodeAndSignIn(authCode: $authCode, phoneNumber: $phoneNumber, email: $email)
  {
    access_token
    refresh_token
    user{
      id
      base_credit_limit {amount}
      extra_credit_limit
      active_order_limit
      is_active
      cnic_number
      address_line1
      user_type
    }
  }
}
`

export const VALIDATE_AUTH_NO_SIGN = gql`
query ValidateAuthCode($authCode: String!, $phoneNumber: String, $email: String) {
    validateAuthCode(authCode: $authCode, phoneNumber: $phoneNumber, email: $email)
}
`


export const USER_EXIST_VERIFY = gql`
query UserExists($email: String, $mobile: String, $cnic_number: String) {
    userExists(email: $email, mobile: $mobile, cnic_number: $cnic_number)
}`

export const USER_ACTIVE_VERIFY = gql`
query UserActive($email: String, $mobile: String, $cnic_number: String) {
    userActive(email: $email, mobile: $mobile, cnic_number: $cnic_number)
}`  

export const GET_ACTIVE_USER_ORDERS = gql`
query getActiveOrdersOfUser {
  getActiveOrdersOfUser {
    count
  }
}
`

export const INSTALLMENT_PLAN = gql`
query GetInstallmentPlan($id: String!, $totalAmount: Float!) {
    getInstallmentPlan(id: $id, totalAmount: $totalAmount) {

        totalInstallments
        installments
        {
            amount
            date
        }
    }
}
`

export const PAY_VIA_CHECKOUT = gql`
query PayCheckoutOrderAmount($orderId: String!, $cardDetails: CardDetails!){
  payCheckoutOrderAmount(orderId: $orderId, cardDetails: $cardDetails){
    redirectionLink
  }
}
`

export const PAY_VIA_PAYMOB = gql`
query makePayment($orderId: String!, $paymentMethod: PaymentMethodEnumerator!) {
    makePayment(orderId: $orderId, paymentMethod:$paymentMethod)
  }
`

export const EKYC = gql`
    mutation uploadCNIC($cnicFront:Upload!, $cnicBack:Upload!, $phone: String!) {
        uploadCNIC(cnicFront: $cnicFront, cnicBack: $cnicBack, phoneNumber: $phone)
    }
`

export const SIGNUP = gql`
    mutation signUp ($cnicDetails: CNIC!, $selfie: Upload!, $cnicFrontSidePath: String!,
     $cnicBackSidePath: String!, $email: String!, $phoneNumber: String!) {
        signUp(cnicDetails: $cnicDetails, selfie: $selfie, cnicFrontSidePath: $cnicFrontSidePath,
        cnicBackSidePath: $cnicBackSidePath, email: $email, phoneNumber: $phoneNumber){
          access_token
          refresh_token
          user {
              id
              base_credit_limit {amount}
              extra_credit_limit
              active_order_limit
              is_active
          }
        }
    }
`

export const RESIGNUP = gql`
    mutation reSignUp ($cnicDetails: CNIC!, $selfie: Upload!, $cnicFrontSidePath: String!,
     $cnicBackSidePath: String!, $email: String!, $phoneNumber: String!) {
        reSignUp(cnicDetails: $cnicDetails, selfie: $selfie, cnicFrontSidePath: $cnicFrontSidePath,
        cnicBackSidePath: $cnicBackSidePath, email: $email, phoneNumber: $phoneNumber){
          access_token
          refresh_token
          user {
              id
              base_credit_limit {amount}
              extra_credit_limit
              active_order_limit
              is_active
          }
        }
    }
`

export const PAYFASTTOKEN = gql`
    query PayPayFastOrderAmount($orderId: String!) {
        payPayFastOrderAmount(orderId: $orderId)
    }
`

export const ORDERSTATUSIDBYNAME = gql`
    query getOrderStatusByName($name:String!) {
        getOrderStatusByName(name:$name) {
            id
            name
        }
    }
`

export const STORE_ID_TRANSLATE = gql`
    query storeExists($id: String!) {
      storeExists(id:$id)
    }

`