import React from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import { regexCollection } from "../../utilities/Util";
import date from "moment";

const PaymentModal = (props) => {

    const [state, setState] = React.useState({
        cardHolderName: "",
        cardNumber: "",
        expiryDate: "",
        cardCVV: "",
    })

    const handleChange = (event) => {
        localStorage.setItem('time_p4', date.now())
        let value = event.target.value;
        if (event.target.name === 'expiryDate') {
            if (value.length === 2) {
                value = value + "/";
            }
        }
        setState({
            ...state,
            [event.target.name]: value
        });
    }

    return (
        <React.Fragment>
             <Modal
                isOpen={props.showPdfPopup}
                centered={true}
                size="md"
            >
                <ModalHeader className='d-flex justify-content-center' close={props.closePdfBtn}>
                    Terms And Conditions
                </ModalHeader>
                <ModalBody>
                    <div className="mb-3" >
                    {/* style={{width: '100%', height: 500 }} */}
                        <iframe src="https://tnc-mainfolder.s3.us-west-2.amazonaws.com/tcv1.1.pdf" className='modal-iframe'></iframe>
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                isOpen={props.showPopup}
                centered={true}
                size="sm"
            >
                <ModalHeader close={props.closeBtn}>
                    Enter Card Details
                </ModalHeader>
                <ModalBody>
                    <div className="mb-3">
                        <AvField
                            name="cardHolderNumber"
                            label={`Card Holder Name`}
                            type="text"
                            placeholder="Card Holder Name"
                            validate={{
                                required: true,
                            }}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <AvField
                            name="cardNumber"
                            label={`Card Number`}
                            type="text"
                            placeholder="Card Number"
                            errorMessage="Card number should be 16 digits long"
                            value={state.cardNumber}
                            required
                            validate={{
                                required: { value: true },
                                pattern: { value: regexCollection.cardNumber },
                                minLength: { value: 16 },
                                maxLength: { value: 16 }
                            }}
                            onChange={handleChange}
                        />
                    </div>
                    <div
                        className="mb-3 payment-modal-popup"
                    >
                        <AvField
                            className="payment-modal-width"
                            name="expiryDate"
                            label={`Expiry Date`}
                            type="text"
                            placeholder="MM/YY"
                            errorMessage="Invalid Expiry Date"
                            value={state.expiryDate}
                            validate={{
                                required: true,
                                minLength: { value: 5 },
                                maxLength: { value: 5 },
                            }}
                            onChange={handleChange}
                            required
                        />
                        <AvField
                            name="cardCVV"
                            className="payment-modal-width"
                            label={`CVV`}
                            type="number"
                            value={state.cardCVV}
                            placeholder="CVV"
                            errorMessage="Invalid CVV"
                            validate={{
                                required: true,
                                pattern: { value: regexCollection.cvv },
                                minLength: { value: 3 },
                                maxLength: { value: 3 }
                            }}
                            onChange={handleChange}
                        />
                    </div>

                    <h5 className='error-message text-left'>
                        {props.error}
                    </h5>

                    <button
                        className='payment-button-style'
                        to="#"
                        onClick={() => {
                            {
                                props.payOrder(state);
                            }
                        }}
                    >
                        Pay Rs.{" "}
                        {props.orderDetails.installmentBreakDown["0"]?.["amount"]}
                    </button>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}
export default PaymentModal;
