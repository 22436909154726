import {
	AvRadioGroup,
	AvRadio
} from "availity-reactstrap-validation";
const PaymentRadioButton = (props) => {

    const paymentTypeHandler = (e) => {
        props.setPaymentType(e.target.value);
    }

    return (
        <AvRadioGroup
            name="information.platform"
            required
            inline
            onChange={paymentTypeHandler}
        >
            <div className="d-flex justify-content-between">
                <AvRadio
                    label="Credit/Debit Card"
                    value="CARD"
                />
                <br />

                {/*<AvRadio*/}
                {/*    label="EasyPaisa"*/}
                {/*    value="EASYPAISA"*/}
                {/*/>*/}
                {/*<br />*/}

                <AvRadio
                    label="JazzCash"
                    value="JAZZCASH"
                />
                <br />

                {/*<AvRadio*/}
                {/*    label="Bank Transfer"*/}
                {/*    value="NIFT"*/}
                {/*/>*/}
                {/*<br />*/}
            </div>
        </AvRadioGroup>
    )
}
export default PaymentRadioButton 