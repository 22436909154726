import React from 'react'
import classnames from "classnames";
import {
    NavItem,
    NavLink
} from "reactstrap";

const TopNavBar = (props) => {
    return (
        <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
            <NavItem>
                <NavLink
                    className={classnames({
                        active: props.activeTab === 1,
                    })}
                >
                    <span
                        className="step-number font-size-20"
                    >
                        1
                    </span>
                    <span
                        className="step-title"
                    >
                        Customer Information
                    </span>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({
                        active: props.activeTab === 2,
                    })}
                >
                    <span
                        className="step-number font-size-20"
                    >
                        2
                    </span>
                    <span
                        className="step-title"
                    >
                        OTP Code Verification
                    </span>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({
                        active: props.activeTab === 3,
                    })}
                >
                    <span
                        className="step-number font-size-20"
                    >
                        3
                    </span>
                    <span
                        className="step-title"
                    >
                        Customer Sign Up
                    </span>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({
                        active: props.activeTab === 4,
                    })}
                >
                    <span
                        className="step-number font-size-20"
                    >
                        4
                    </span>
                    <span
                        className="step-title"
                    >
                        Payment Method
                    </span>
                </NavLink>
            </NavItem>
        </ul>
    )
}
export default TopNavBar;