import React, { Component } from "react";
import {
	Switch,
	BrowserRouter as Router,
	Route
} from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import Register from "./pages/Authentication/Register";
import Payfast from "./pages/Authentication/Payfast";
import Successful from "./pages/Authentication/Successful";
import Failure from "./pages/Authentication/Failure";
import Successful_installment from "./pages/Authentication/Successful_installment";
import PayMobCompletion from "./pages/Authentication/paymobcompletion";

import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

import "./assets/scss/theme.scss";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./services/GQLServices";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount = async () => {
		// console.log("Layout ", this.props);
	};


	render() {
		return (
			<React.Fragment>
				<ApolloProvider client={apolloClient}>
					{/* <QueryClientProvider client={queryClient}> */}
					<Router>
						<Switch>
							<Route path={"/checkout"} component={Register} />
							<Route path={"/pay"} component={Payfast} />
							<Route path={"/complete"} component={Successful} />
							<Route path={"/failure"} component={Failure} />
							<Route path={"/installment"} component={Successful_installment} />
							<Route path = {'/paymobcompletion'} component={PayMobCompletion}/>
						</Switch>
					</Router>
					{/*<ReactQueryDevtools initialIsOpen={false} />*/}
					{/* </QueryClientProvider> */}
				</ApolloProvider>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		layout: state.Layout,
	};
};

export default connect(mapStateToProps, null)(App);