import {
    ADD_ORDER,
    apolloClient,
    CREATE_AUTH_CODE,
    INSTALLMENT_PLAN,
    PAYFASTTOKEN,
    SIGNUP,
    RESIGNUP,
    STORE_ID,
    STORE_ID_TRANSLATE,
    UPDATE_ORDER,
    USER_EXIST_VERIFY,
    USER_ACTIVE_VERIFY,
    VALIDATE_AUTH_NO_SIGN,
    VALIDATE_AUTH_SIGN
} from "./GQLServices";
import {errorMessages} from "../utilities/Util";
import { formatDateToYYYYMMDD } from './misc_services';

export const createTempOrder = async (parsed_info, setShowPopupErrorPermanent, setError) => {
    try {
        const response = await apolloClient.mutate({
            mutation: ADD_ORDER, variables: {
                'order': {
                    'user_id': null,
                    'store_id': parsed_info.merchant_id,
                    'number_items': parsed_info.items.length,
                    'shipping_cost': parsed_info.delivery_charges,
                    'order_level_adj': 0,
                    'total_price': parsed_info.orderTotal,
                    'order_status': "ABANDONED",
                    'installment_option_id': null,
                    'approval_status': null,
                    'misc_details': {}
                }
            }
        }
        )
        return response.data['addOrderV1'].id
    } catch (error) {
        setShowPopupErrorPermanent(true)
        setError(errorMessages.orderCreationIssueGQL);
        return error;
    }
}

export const merchantAndMinimumAmountCheck = async (parsed_info, orderDetails, setOrderDetails, setShowPopupErrorPermanent, setError) => {
    try {
        const response = await apolloClient.query({ query: STORE_ID, variables: { 'id': parsed_info.merchant_id } })
        if (response.data['getStoreV1']['minimum_amount'] > parsed_info['orderTotal']) {
            setShowPopupErrorPermanent(true)
            setError(errorMessages.belowMinimumOrderLimit)
            return false
        }
        return response.data['getStoreV1']['installment_options']["0"]?.id
    } catch (error) {
        if (error.graphQLErrors["0"]?.message === "NOT_FOUND") {
            setShowPopupErrorPermanent(true)
            setError(errorMessages.unknownStore);
            return false;
        }
    }
}

export const checkUserExists = async (phone, setNewUser) => {
    try {
        const response = await apolloClient.query({
            query: USER_EXIST_VERIFY, variables: {
                'mobile': phone
            }
        }
        )
        return response.data['userExists']
    } catch (error) {
        console.log(error)
    }
}

export const checkUserActive = async (phone) => {
    try {
        const response = await apolloClient.query({
            query: USER_ACTIVE_VERIFY, variables: {
                'mobile': phone
            }
        }
        )
        return response.data['userActive']
    } catch (error) {
        console.log(error)
    }
}

export const OTPService = async (phone) => {
    try {
        const response = await apolloClient.mutate({
            mutation: CREATE_AUTH_CODE, variables: {
                'phone': phone
            }
        }
        )
        // console.log(response)
    } catch (error) {
        // let error_string = 'cannot send OTP'
        // setShowPopupError(true)
        // setError(error_string)
        // return error_string;
    }
}

export const updateOrder = async (kalpayOrderid, order_details) => {
    await apolloClient.mutate({
        mutation: UPDATE_ORDER,
        variables: {
            'id': kalpayOrderid,
            'order': order_details
        }
    })
}

export const signInValidateUser = async (value, phone, setError, setLoading, setToken, setorderErrorReason, setallowOrder, setShowPopupErrorPermanent) => {
    try {
        let userData = await apolloClient.mutate({
            mutation: VALIDATE_AUTH_SIGN, variables: {
                'authCode': value,
                'phoneNumber': phone
            }
        }
        )
        userData = {
            'access_token': userData.data['validateAuthCodeAndSignIn'].access_token,
            'refresh_token': userData.data['validateAuthCodeAndSignIn'].refresh_token,
            'user': userData.data['validateAuthCodeAndSignIn'].user,
        }
        if (userData?.user?.user_type === 'MERCHANT') {
            setShowPopupErrorPermanent(true)
            setError(errorMessages.USER_IS_MERCHANT);
            return false;
        }

        setToken(userData['access_token']);
        sessionStorage.setItem('token', userData['access_token']);
        return userData['user']
    } catch (error) {
        setError("Enter correct OTP")
        setLoading(false)
        return false
    }

}

export const checkUserCompletion = async (userInfo) => {
    if (!userInfo['cnic_number' || userInfo['cnic_number'].length !== 15 || !userInfo['address_line1'] ]){
        return false
    }
    return true
}


export const validateAuth = async (value, phone, setError, setLoading) => {
    try {
        const response = await apolloClient.query({
            query: VALIDATE_AUTH_NO_SIGN, variables: {
                'authCode': value,
                'phoneNumber': phone
            }
        })
        if (!response['data']['validateAuthCode']) {
            setError("Enter correct OTP")
            setLoading(false);
            return false;
        }
        return true
    } catch (error) {
        setError("Enter correct OTP");
        setLoading(false);
        return false;
    }
}

export const getInstallmentPlan = async (orderDetails, setOrderDetails) => {
    try {
        const installmentPlan = await apolloClient.query({
            query: INSTALLMENT_PLAN, variables: {
                'id': orderDetails['installmentPlanId'], 'totalAmount': orderDetails['orderTotal']
            }
        })
        sessionStorage.setItem("downpayment", installmentPlan.data['getInstallmentPlan']['installments']["0"].amount)
        setOrderDetails({...orderDetails, totalInstallments:installmentPlan.data['getInstallmentPlan']['totalInstallments'],
            installmentBreakDown:installmentPlan.data['getInstallmentPlan']['installments']})
    } catch (error) {
        console.log(error)
    }

}

const generateSignUpError = async (error, setError, setShowPopupError) => {
    if (error.graphQLErrors[0].code === 304 && error.graphQLErrors[0].message === "user not created. Try again or contact your system admin NOT_MODIFIED"){
        setError(errorMessages.NOT_MODIFIED_304_SIGNUP)
    }
    else if (error?.graphQLErrors["0"]?.message){
        setError(error.graphQLErrors["0"].message.replace(" BAD_REQUEST", "."))
    }
    else {
        setError(errorMessages.internalError)
    }
    setShowPopupError(true)
    return false
}

export const signUpAndValidate = async (userDetails, frontImageSrc, cnicFrontSidePath, cnicBackSidePath,
    setToken, setorderErrorReason, setallowOrder, setError, setLoading, setShowPopupError) => {
    try {
        let userData = await apolloClient.mutate({
            mutation: SIGNUP, variables: {
                cnicDetails: {
                    "type": userDetails["cardType"],
                    "firstName": userDetails["firstName"],
                    "lastName": userDetails["lastName"],
                    "fatherName": userDetails["lastName"],
                    "cnicNumber": userDetails["cnic"],
                    "husbandName": userDetails["husbandName"] ? userDetails["husbandName"] : "",
                    "motherName": userDetails["motherName"] ? userDetails["motherName"] : "",
                    "cityOfBirth": userDetails["city"],
                    "dateOfBirth": formatDateToYYYYMMDD(userDetails["dateOfBirth"]),
                    "validTill": userDetails["dateOfExpiry"].toLowerCase()==='lifetime'?'lifetime': formatDateToYYYYMMDD(userDetails["dateOfExpiry"]),
                    "validFrom": formatDateToYYYYMMDD(userDetails["dateOfIssue"]),
                    "address": userDetails["address"],
                    "gender": userDetails["gender"],
                },
                selfie: frontImageSrc,
                cnicFrontSidePath: cnicFrontSidePath,
                cnicBackSidePath: cnicBackSidePath,
                email: userDetails["email"],
                phoneNumber: userDetails["phone"],
            }
        })
        userData = {
            'access_token': userData.data['signUp'].access_token,
            'refresh_token': userData.data['signUp'].refresh_token,
            'user': userData.data['signUp'].user
        }
        setToken(userData['access_token']);
        sessionStorage.setItem('token', userData['access_token']);
        return userData['user']
    } catch (error) {
        return await generateSignUpError(error, setError, setShowPopupError)
    }
}

export const reSignUpAndValidate = async (userDetails, frontImageSrc, cnicFrontSidePath, cnicBackSidePath,
    setToken, setorderErrorReason, setallowOrder, setError, setLoading, setShowPopupError) => {
    try {
        let userData = await apolloClient.mutate({
            mutation: RESIGNUP, variables: {
                cnicDetails: {
                    	"type": userDetails["cardType"],
                    	"firstName": userDetails["firstName"],
                        "lastName": userDetails["lastName"],
                        "fatherName": userDetails["lastName"],
                        "cnicNumber": userDetails["cnic"],
                    	"husbandName": userDetails["husbandName"] ? userDetails["husbandName"] : "",
                    	"motherName": userDetails["motherName"] ? userDetails["motherName"] : "",
                    	"cityOfBirth": userDetails["city"],
                    	"dateOfBirth": formatDateToYYYYMMDD(userDetails["dateOfBirth"]),
                    	"validTill": userDetails["dateOfExpiry"].toLowerCase()==='lifetime'?'lifetime': formatDateToYYYYMMDD(userDetails["dateOfExpiry"]),
                    	"validFrom": formatDateToYYYYMMDD(userDetails["dateOfIssue"]),
                    	"address": userDetails["address"],
                    	"gender": userDetails["gender"],
                },
                selfie: frontImageSrc,
                cnicFrontSidePath: cnicFrontSidePath,
                cnicBackSidePath: cnicBackSidePath,
                email: userDetails["email"],
                phoneNumber: userDetails["phone"],
            }
        })
        userData = {
            'access_token': userData.data['reSignUp'].access_token,
            'refresh_token': userData.data['reSignUp'].refresh_token,
            'user': userData.data['reSignUp'].user
        }
        setToken(userData['access_token']);
        sessionStorage.setItem('token', userData['access_token']);
        return userData['user']
    } catch (error) {
        return await generateSignUpError(error, setError, setShowPopupError)
    }
}

export const getPayFastToken = async (kalpayOrderId) => {
    try {
        let token = await apolloClient.query({query: PAYFASTTOKEN, variables:{'orderId': kalpayOrderId}})
        return token.data['payPayFastOrderAmount']
    } catch (error) {
        console.log(error)
        return false
    }
}

export const storeIdCheck = async (merchantId) => {
    try {
        let result = await apolloClient.query({query: STORE_ID_TRANSLATE, variables:{'id': merchantId}})
        return result.data['storeExists'].id
    } catch (error) {
        return false
    }
}