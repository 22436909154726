import React, { useEffect, useState } from 'react';
import { Row, Col } from "reactstrap";
import ReactCrop, { centerCrop, makeAspectCrop, } from 'react-image-crop';
import { AvCheckbox, AvCheckboxGroup, AvField } from "availity-reactstrap-validation";
import { errorMessages, placeHolders, regexCollection } from "../../utilities/Util";

function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

const NewCustomer = (props) => {
    const [crop, setCrop] = useState()
    // const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(16 / 9)
    const [checked, setChecked] = useState("off")

    const { frontImageData, setFrontImageData, setBackImageData, backImageData,
        frontImageSrc, setFrontImageSrc, backImageSrc, setBackImageSrc, cnicUploaded, userDetailsEdit,
        setShowPdfPopup
    } = props;


    const handleChange = (evt) => {
        let value = evt.target.value;
        if (evt.target.name === 'cnic' && value.length === 15) {
            if (parseInt(value[value.length - 1]) % 2 === 0) {
                props.setUserDetails({ ...props.userDetails, 'gender': "FEMALE", [evt.target.name]: value });
            } else {
                props.setUserDetails({ ...props.userDetails, 'gender': "MALE", [evt.target.name]: value });
            }
        } else {
            props.setUserDetails({ ...props.userDetails, [evt.target.name]: value });
        }
    }

    // const handleChecked = (evt) => {
    //     let value = evt.target.value;
    //     setChecked(value)
    // }

    const onChangeFront = async ({
        target: {
            validity,
            files: [file],
        },
    }) => {
        if (validity.valid)
            setFrontImageSrc(file);

        onSelectFile(file, setFrontImageData)
    }

    const onChangeBack = ({
        target: {
            validity,
            files: [file],
        },
    }) => {
        if (validity.valid)
            setBackImageSrc(file);

        onSelectFile(file, setBackImageData)
    }

    const onSelectFile = (fileInput, setImageData) => {
        function readAndPreview(file) {
            if (/\.(jpe?g|png|gif)$/i.test(file?.name)) {
                setCrop(undefined) // Makes crop preview update between images.
                const reader = new FileReader()
                reader.addEventListener("load", () => {
                    const image = new Image();
                    image.title = file.name;
                    image.type = file.type;
                    image.size = file.size;
                    setImageData({
                        image,
                        result: reader.result.toString() || ''
                    })
                }, false);

                reader.readAsDataURL(file);
            }
        }
        readAndPreview(fileInput)
        // console.log(fileInput, 'fileInput');

    }
    // console.log(readAndPreview(fileInput), 'readAndPreview(fileInput)'


    const onImageLoad = (e) => {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    return (
        <React.Fragment>
            {!cnicUploaded ?

                <div className="justify-content-center">
                    <Row className="d-flex justify-content-center">
                        <Col sm="0" md="3" lg="3"></Col>
                        <div className="App">
                            {/* <div className="Crop-Controls">
                                <h5>Upload FrontSide of Cnic</h5>
                                <input multiple={false} id="browse" type="file" onChange={(e) => {
                                    onChangeFront(e)
                                }
                                } />
                            </div> */}
                            {/* <p>Custom file:</p> */}
                            <div class="Crop-Controls">
                                <h6 className="image-heading">Front Side Cnic</h6>
                                <input class="form-control" multiple={false} id="formFile" type="file" onChange={(e) => {
                                    onChangeFront(e)
                                }
                                } />
                                {/* <label class="custom-file-label" for="customFile">Choose file</label> */}
                            </div>
                            {frontImageSrc && (
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    // onComplete={(c) => setCompletedCrop(c)}
                                    width='300px'
                                    height='180px'
                                    key="frontside"
                                >   
                                    <div class="container">
                                    <img
                                        alt="Crop me"
                                        src={frontImageData?.result || ''}
                                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                        onLoad={onImageLoad}
                                        width='250px'
                                        height='130px'
                                    />
                                        <div class="watermark-overlay">
                                            <div class="watermark-text">For KalPay Use Only</div>
                                        </div>
                                    </div>
                                    
                                </ReactCrop>
                            )}
                        </div>
                        <Col sm="0" md="3" lg="3"></Col>
                    </Row>

                    <Row className="">
                        <Col sm="0" md="3" lg="3"></Col>
                        <div className="App">
                            <div className="Crop-Controls">
                                <h6 className="image-heading">Back Side Cnic</h6>
                                <input class="form-control" multiple={false} id="formFile" type="file" onChange={(e) => {
                                    onChangeBack(e)
                                }} />
                            </div>
                            {backImageSrc && (
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    // onComplete={(c) => setCompletedCrop(c)}
                                    width='300px'
                                    height='180px'
                                    key="backimage"
                                >
                                    <div class="container">
                                        <img
                                            alt="Crop me"
                                            src={backImageData?.result || ''}
                                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                            onLoad={onImageLoad}
                                            width='250px'
                                            height='130px'
                                        />
                                        <div class="watermark-overlay">
                                            <div class="watermark-text">For KalPay Use Only</div>
                                        </div>
                                    </div>

                                </ReactCrop>
                            )}
                            {/* <button onClick={() => uploadCNICPhotos()}>Upload {console.log('Uploading...')}</button> */}
                        </div>

                        <Col sm="0" md="3" lg="3"></Col>
                    </Row>
                </div>
                :
                <div className="justify-content-center">
                    <Row>
                        <Col sm="6" md="6" lg="6">
                            <div>
                                <div className="mb-3">
                                    <h6 className="d-flex">First Name*</h6>
                                    <AvField
                                        name="firstName"
                                        placeholder={placeHolders.firstName}
                                        type="text"
                                        errorMessage={errorMessages.firstName}
                                        validate={{
                                            required: true,
                                        }}
                                        value={props.userDetails["firstName"]}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col sm="6" md="6" lg="6">
                            <div>
                                <div className="mb-3" style={{ padding: "0 0 0 0px" }}>
                                    <h6 className="d-flex">Last Name*</h6>
                                    <AvField
                                        name="lastName"
                                        className="ml-2"
                                        type="text"
                                        placeholder={placeHolders.lastName}
                                        errorMessage={errorMessages.lastName}
                                        validate={{
                                            required: true,
                                        }}
                                        value={props.userDetails["lastName"]}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6" md="6" lg="6">
                            <div >
                                <div className="mb-3 ">
                                    <h6 className="d-flex">Email Address*</h6>
                                    <AvField
                                        className="d-flex"
                                        name="email"
                                        type="text"
                                        placeholder={placeHolders.email}
                                        errorMessage={errorMessages.email}
                                        validate={{
                                            required: true,
                                        }}
                                        value={props.userDetails["email"]}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col sm="6" md="6" lg="6">
                            <div>
                                <div className="mb-3" style={{ padding: "0 0 0 0px" }}>
                                    <h6 className="d-flex">Mother Name*</h6>
                                    <AvField
                                        name="motherName"
                                        className="ml-2"
                                        type="text"
                                        placeholder={placeHolders.motherName}
                                        errorMessage={errorMessages.motherName}
                                        validate={{
                                            required: true,
                                        }}
                                        value={props.userDetails["motherName"]}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6" md="6" lg="6">
                            <div>
                                <div className="mb-3" style={{ padding: "0 0 0 0px" }}>
                                    <h6 className="d-flex">CNIC*</h6>
                                    <AvField
                                        name="cnic"
                                        type="string"
                                        disabled={!userDetailsEdit['cnic']}
                                        placeholder={placeHolders.cnic}
                                        errorMessage={errorMessages.cnic}
                                        value={props.userDetails["cnic"]}
                                        validate={{
                                            required: true,
                                            maxLength: { value: 15 },
                                            pattern: { value: regexCollection.cnic },
                                        }}
                                        required
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col sm="6" md="6" lg="6">
                            <div>
                                <div className="mb-3">
                                    <h6 className="d-flex">Date of Birth*</h6>
                                    <AvField
                                        name="dateOfBirth"
                                        type="text"
                                        disabled={!userDetailsEdit['dateOfBirth']}
                                        placeholder={placeHolders.date}
                                        errorMessage={errorMessages.dateOfBirth}
                                        validate={{ required: true, date: { format: 'DD/MM/YYYY' } }}
                                        value={props.userDetails["dateOfBirth"]}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6" md="6" lg="6">
                            <div>
                                <div className="mb-3">
                                    <h6 className="d-flex">Date of Issue*</h6>
                                    <AvField
                                        name="dateOfIssue"
                                        type="string"
                                        disabled={!userDetailsEdit['dateOfIssue']}
                                        placeholder={placeHolders.date}
                                        errorMessage={errorMessages.dateOfIssue}
                                        validate={{ required: true, date: { format: 'DD/MM/YYYY' } }}
                                        value={props.userDetails["dateOfIssue"]}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col sm="6" md="6" lg="6">
                            <div>
                                <div className="mb-3" style={{ padding: "0 0 0 0px" }}>
                                    <h6 className="d-flex">Date of Expiry*</h6>
                                    <AvField
                                        name="dateOfExpiry"
                                        type="string"
                                        disabled={!userDetailsEdit['dateOfExpiry']}
                                        placeholder={placeHolders.date + "  or  Lifetime"}
                                        errorMessage={errorMessages.dateOfExpiry}
                                        value={props.userDetails.dateOfExpiry}
                                        validate={{
                                            required: true,
                                            pattern: { value: regexCollection.expiry },
                                        }}
                                        required
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col sm="6" md="6" lg="6">
                            <div>
                                <div className="mb-3">
                                    <h6 className="d-flex">Address*</h6>
                                    <AvField
                                        name="address"
                                        type="text"
                                        placeholder={placeHolders.address}
                                        errorMessage={errorMessages.address}
                                        validate={{
                                            required: true,
                                        }}
                                        value={props.userDetails["address"]}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col sm="6" md="6" lg="6">
                            <div>
                                <div className="mb-3" style={{ padding: "0 0 0 0px" }}>
                                    <h6 className="d-flex">City*</h6>
                                    <AvField
                                        name="city"
                                        type="text"
                                        placeholder={placeHolders.city}
                                        errorMessage={errorMessages.city}
                                        validate={{
                                            required: true,
                                        }}
                                        value={props.userDetails["city"]}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="d-flex">
                        <Row>
                            <div className='d-flex'>
                                <input type="checkbox"
                                    name='requiredCheckbox'
                                    required
                                    onChange={handleChange}
                                />
                                <p className='terms'>I have read the</p>
                                <p className='text-danger terms terms-font' onClick={setShowPdfPopup}>Terms And Conditions</p>
                            </div>
                        </Row>
                    </div>
                </div>}
            <h4 className='error-message'>{props.error}</h4>
        </React.Fragment>
    )
}
export default NewCustomer;