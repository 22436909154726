import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, useParams } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";

const app = (
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
