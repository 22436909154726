import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Card, CardBody, TabContent, TabPane, Container, Spinner, Modal, ModalHeader, } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import logo from "../../assets/images/LogoHighRes.png";
import { errorMessages, validatePhoneNumber } from "../../utilities/Util";
import Otp from "./Otp";
import PaymentSummary from "./PaymentSummary";
import PaymentRadioButton from "./PaymentRadioButton";
import PaymentModal from "./PaymentModal";
import NewCustomer from "./NewCustomer";
import EnterPhone from "./EnterPhone";
import TopNavBar from "./TopNavBar";
import NavigationButton from "./NavigationButton";
import Footer from "./Footer";
import { useMutation } from "@apollo/client";
import { apolloClient, EKYC, PAY_VIA_CHECKOUT, PAY_VIA_PAYMOB } from "../../services/GQLServices";
import { signUpFields, uploadImageClickHandler, URLParser, validatorUser } from "../../services/misc_services";
import {
	checkUserExists, checkUserActive, createTempOrder, getInstallmentPlan, getPayFastToken, merchantAndMinimumAmountCheck, OTPService,
	signInValidateUser, signUpAndValidate, reSignUpAndValidate, storeIdCheck, updateOrder, validateAuth
} from "../../services/GQLCommands";
import { isCreditCard } from "class-validator";
import { configURLs } from "../../config/utils";
import date from "moment";

const Register = (props) => {
	//UI related
	const [activeTab, setActiveTab] = useState(1);
	const [error, setError] = useState("");
	const [showPopupErrorPermanent, setShowPopupErrorPermanent] = useState(false);
	const [showPopupError, setShowPopupError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [otpVerified, setotpVerified] = useState(false)
	const [showPopup, setShowPopup] = useState(false);
	const [showPdfPopup, setShowPdfPopup] = useState(false);

	//Order details
	const [orderDetails, setOrderDetails] = useState({
		storeId: "", storeOrderId: "", kalpayOrderId: "", orderItems: [], deliveryCharges: 0, orderTotal: 0,
		redirectUrl: "", totalInstallments: 3, installmentBreakDown: [{ 'date': 1, 'amount': 1 }],
		installmentPlanId: "", source: ""
	}
	)


	//User details, post signup i.e. credit limits, orders allowed, tokens
	const [creditLimit, setCreditLimit] = useState(0)
	const [allowOrder, setallowOrder] = useState(true)
	const [orderErrorReason, setorderErrorReason] = useState("")
	const [token, setToken] = useState("");
	const [method, setMethod] = useState("");
	const [paymentType, setPaymentType] = useState("");

	//User details i.e. address
	const [userDetails, setUserDetails] = useState({
		firstName: "", lastName: "", phone: "", motherName: "", gender: "", cardType: "", cnic: "", email: "",
		address: "", city: "", dateOfBirth: "", dateOfIssue: "", dateOfExpiry: "", requiredCheckbox: "off"
	}
	)
	const [userDetailsEdit, setUserDetailsEdit] = useState({
			cnic: false, dateOfBirth: false, dateOfIssue: false, dateOfExpiry: false
		}
	)
	const [badUser, setBadUser] = useState(false);
	const [newUser, setNewUser] = useState(false);
	const [code, setCode] = useState("");

	const [frontImageData, setFrontImageData] = useState();
	const [backImageData, setBackImageData] = useState();
	const [frontImageSrc, setFrontImageSrc] = useState();
	const [backImageSrc, setBackImageSrc] = useState();
	const [cnicUploaded, setCnicUploaded] = useState(false);
	const [cnicFrontSidePath, setCnicFrontSidePath] = useState();
	const [cnicBackSidePath, setCnicBackSidePath] = useState();
	const [cnicUploadError, setCnicUploadError] = useState(false);
	const [mutate, { loading: filesUploading }] = useMutation(EKYC);

	//Card details
	const [timeLeft, setTimeLeft] = useState(60);

	useEffect(() => {
		const timer = setInterval(() => {
			if (timeLeft != 0) {
				setTimeLeft(timeLeft - 1);
			}
		}, 1000);
		return () => clearInterval(timer);
	}, [timeLeft]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		window.history.pushState({}, null, "/checkout");
		localStorage.clear();
		sessionStorage.clear()
		let params_raw = ""
		if (props.location.pathname.length > props.location.search.length) {
			params_raw = props.location.pathname.split("/checkout/")[1];
		} else {
			params_raw = props.location.search
		}
		let params = Object.fromEntries(new URLSearchParams(params_raw))
		if (Object.keys(params).length < 3) {
			setError(errorMessages.invalidURL)
			setShowPopupErrorPermanent(true)
			return
		}
		let parsed_info = await saveURLParams(params, params_raw);
		if (!parsed_info.merchant_id || !parsed_info.amount_without_delivery) {
			setError(errorMessages.incompleteURL)
			setShowPopupErrorPermanent(true)
			return
		}
		parsed_info.merchant_id = await storeIdCheck(parsed_info.merchant_id)
		if (!parsed_info.merchant_id) {
			setError(errorMessages.unknownStore)
			setShowPopupErrorPermanent(true)
			return
		}
		let installmentPlanId = await merchantAndMinimumAmountCheck(parsed_info, orderDetails, setOrderDetails,
			setShowPopupErrorPermanent, setError)
		if (!installmentPlanId) {
			return
		}
		setOrderDetails({
			...orderDetails,
			storeOrderId: parsed_info['order_id'] ? parsed_info['order_id'] : null,
			storeId: parsed_info['merchant_id'],
			orderTotal: parsed_info['orderTotal'],
			redirectUrl: parsed_info['callback'] ? parsed_info['callback'] : configURLs.baseFallBackReDirectionURL,
			deliveryCharges: parsed_info['delivery_charges'] ? parsed_info['delivery_charges'] : 0,
			source: parsed_info['source'] ? parsed_info['source'] : 3,
			orderItems: parsed_info['items'],
			kalpayOrderId: await createTempOrder(parsed_info, setShowPopupErrorPermanent, setError),
			installmentPlanId: installmentPlanId
		})

	}, []);

	const saveURLParams = async (splitting, raw_params) => {
		let parsed_info = await URLParser(splitting, raw_params);
		setUserDetails({
			...userDetails,
			"firstName": parsed_info['first_name'], "city": parsed_info["city"],
			"lastName": parsed_info['last_name'], "email": parsed_info['email'], "address": parsed_info["address"]
		})

		return parsed_info
	};

	const handleSubmit = (event, errors, values, history) => { };

	const postUserAuth = async (userInfo) => {
		await validatorUser(userInfo, setorderErrorReason, setallowOrder, setCreditLimit, orderDetails['orderTotal'])
		await getInstallmentPlan(orderDetails, setOrderDetails)
		try {
			await updateOrder(orderDetails['kalpayOrderId'], {
				'user_id': userInfo['id'],
				"installment_option_id": orderDetails['installmentPlanId'], 'order_status': "CREATED",
				'misc_details': {'pluginSource': orderDetails['source'], 'phone_otp': userDetails["phone"], 'pluginPayment': sessionStorage.getItem("serverCallBack")}, 'external_order_id': orderDetails['storeOrderId'] ? orderDetails['storeOrderId'] : ""
			})
		} catch (error) {
			console.log("error updating order")
		}
	}

	const apiCall = async (calling, value) => {
		setError("");
		setLoading(true);
		let oldUser = true;
		if (calling === 1) {
			let error = validatePhoneNumber(userDetails["phone"])
			if (error) {
				setLoading(false)
				setError(error);
				return;
			}
			oldUser = await checkUserExists(userDetails["phone"], setNewUser)
			setNewUser(!oldUser)
			setBadUser(!await checkUserActive(userDetails["phone"]))
			await OTPService(userDetails["phone"])
			await updateOrder(orderDetails['kalpayOrderId'], { 'misc_details': { 'phone_otp': userDetails["phone"] } })
		}
		if (calling === 2) {
			if (code.length < 1) {
				setLoading(false);
				return;
			}
			let userInfo = null
			if (newUser) {
				if (!await validateAuth(value, userDetails["phone"], setError, setLoading)) {
					return
				}

			} else {
				userInfo = await signInValidateUser(value, userDetails["phone"], setError, setLoading, setToken, setorderErrorReason, setallowOrder, setShowPopupErrorPermanent)
				if (!userInfo) {
					return
				}
				// await checkUserCompletion(userInfo)
				await postUserAuth(userInfo)
			}
		}
		if (calling === 3) {
			if (!cnicUploaded && !cnicUploadError && frontImageSrc && backImageSrc) {
				if (!await uploadImageClickHandler(mutate, frontImageSrc, backImageSrc, userDetails, setUserDetails,
					setCnicFrontSidePath, setCnicBackSidePath, setCnicUploaded, setError, setShowPopupError, userDetailsEdit, setUserDetailsEdit)) {
						setLoading(false)
						return
					}
				}
				else {
					if (!await signUpFields(userDetails, setError, setLoading)) {
						return;
					} else {
						let userInfo = null;
						if (badUser && !newUser) {
							userInfo = await reSignUpAndValidate(userDetails, frontImageSrc, cnicFrontSidePath, cnicBackSidePath,
								setToken, setorderErrorReason, setallowOrder, setError, setLoading, setShowPopupError)
						}
						else {
							userInfo = await signUpAndValidate(userDetails, frontImageSrc, cnicFrontSidePath, cnicBackSidePath,
								setToken, setorderErrorReason, setallowOrder, setError, setLoading, setShowPopupError)
							
						}
						if (!userInfo) {
							setLoading(true);
						} else {
							await postUserAuth(userInfo)
							setNewUser(false);
							setBadUser(false);
							calling = 4
						}
					}
					}
				}
		if (calling === 4) {
			localStorage.setItem('time_p4', date.now())
			if (!allowOrder) {
				setShowPopupError(true);
				setError(orderErrorReason);
				setLoading(false);
				return
			}
			if (['CARD', 'JAZZCASH'].includes(paymentType)) {
				try {
					await apolloClient.mutate({
						mutation: PAY_VIA_PAYMOB, variables: {
							'orderId': orderDetails.kalpayOrderId,
							'paymentMethod': paymentType
						}
					}).then(async (response) => {
						localStorage.setItem('proceed', true);
						window.location.href = response.data['makePayment']['payment_link'];
					}).catch((err) => {
						localStorage.setItem('proceed', false);
						setShowPopup(false);
						setLoading(false);
						setError('Payment failed. Please try again');
					});
				} catch (error) {
					localStorage.setItem('proceed', false);
					console.log(error);
				}
			}
		}
		setLoading(false);
		if (activeTab === 1) {
			setTimeLeft(60);
		}
		if (calling === 4) {
			setActiveTab(calling);
		} else if (activeTab === 2 && !newUser && !badUser) {
			setActiveTab(4)
		} else if (activeTab === 3 && badUser) {
			setActiveTab(calling)
		} 
		else if (activeTab === 3 && newUser) {
			setActiveTab(calling)
		}
		 else {
			setActiveTab(calling + 1);
		}
	};
	const payFastOrder = async () => {
		const payFastInfoToken = await getPayFastToken(orderDetails['kalpayOrderId'])
		if (!payFastInfoToken['access_token']) {
			return
		}
		let date = new Date();
		date =
			date.getFullYear().toString() +
			"-" +
			(date.getMonth() + 1).toString() +
			"-" +
			date.getDate().toString();
		let body = {
			merchant_id: 4414,
			merchant_name: "KalPay Technologies Private Limited",
			token: payFastInfoToken['access_token'],
			procode: "00",
			txnamt: orderDetails.installmentBreakDown["0"]?.['amount'],
			// txnamt: "10",
			customer_mobile_number: userDetails["phone"],
			customer_email_address: userDetails["email"].toLowerCase(),
			signature: "KalPayFinancials",
			version: "MY_VER_1.0",
			txndesc: "KalPay",
			successUrl: configURLs.defaultSuccessUrl,
			failureUrl: configURLs.defaultFailureUrl,
			basket_id: orderDetails.kalpayOrderId,
			order_date: date,
			checkout_url: payFastInfoToken['checkout_url'],
			// checkout_url: `https://6e8b-119-160-58-56.ap.ngrok.io/webhooks/test`
		}
		props.history.push("/pay", { body: body });
	};

	const payOrder = async (state) => {
		if (state.cardNumber.length === 16 && state.cardCVV.length === 3 && isCreditCard(state.cardNumber)) {
			try {
				await apolloClient.mutate({
					mutation: PAY_VIA_CHECKOUT, variables: {
						"orderId": orderDetails.kalpayOrderId,
						"cardDetails": {
							"number": state.cardNumber,
							"expiry_month": parseFloat(state.expiryDate.substring(0, 2)),
							"expiry_year": parseFloat("20" + state.expiryDate.substring(3, 5)),
							"cvv": state.cardCVV
						}
					}
				}).then(async (response) => {
					localStorage.setItem("proceed", true);
					window.location.href = response.data['payCheckoutOrderAmount']['redirectionLink'];
				}).catch((err) => {
					localStorage.setItem("proceed", false);
					setShowPopup(false);
					setLoading(false);
					setError("Payment failed. Please try again");
				})
			} catch (error) {
				localStorage.setItem("proceed", false);
				console.log(error)
			}
		} else {
			localStorage.setItem("proceed", false);
			return setError("invalid Card Information");
		}
	};

	const closeBtn = (
		<button className="close secondary" onClick={() => setShowPopup(false)}>
			&times;
		</button>
	);
	
	const closePdfBtn = (
		<button className="close secondary" onClick={() => setShowPdfPopup(false)}>
			&times;
		</button>
	);
	
	const closeBtnError = (
		<button
			className="close secondary"
			onClick={() => {
				setShowPopupError(false);
				setShowPopupErrorPermanent(false)
			}}
		>
			&times;
		</button>
	);

	return (
		<React.Fragment>
			<Modal
				isOpen={showPopupError}
				centered={true}
				size="sm"
			>
				<ModalHeader close={closeBtnError}>
					<div className='error-message'>{error}</div>
				</ModalHeader>
			</Modal>
			<Modal
				isOpen={showPopupErrorPermanent}
				centered={true}
				size="m"
			>
				<ModalHeader>
					<div className='error-message'>{error}</div>
				</ModalHeader>
			</Modal>
			<div>
				<Container fluid={true}>
					<div className="mt-2 d-flex align-items-center justify-content-center">
						<img className=" d-flex align-items-center justify-content-center" src={logo} alt="" width={200} />
					</div>
					<Row className="d-flex align-items-center justify-content-center mt-2">
						<Col lg="7" md="8" sm="12" xs="12" className="d-flex align-items-center justify-content-center">
							<Card className="card-scroll">
								<CardBody className="card-scroll" >
									<div id="basic-pills-wizard" className="twitter-bs-wizard">
										<TopNavBar
											activeTab={activeTab}
										/>
										<h6 className="steps">Step {activeTab} of 4</h6>
										{loading ? (
											<>
												<div
													className="container"
													style={{
														display: "flex",
														justifyContent: "center",
														minHeight: "35vh",
														alignItems: "center",
														setmarginTop: "110px"
													}}
												>
													<Spinner color="danger" size="md" />
												</div>
											</>
										) : (
											<>
												<AvForm onSubmit={handleSubmit}>
													<TabContent
														activeTab={activeTab}
														className="twitter-bs-wizard-tab-content"
													>
														<TabPane tabId={1}>
															<Row>
																{

																	<div className="mt-2">
																		<h4 className="enter-number">Enter Your Phone Number</h4>
																	</div>

																}
															</Row>
															{
																<Row>

																	<EnterPhone
																		userDetails={userDetails}
																		setUserDetails={setUserDetails}
																		error={error}
																	/>

																	<Col sm="0" md="3" lg="3"></Col>
																</Row>
															}
														</TabPane>
														<TabPane tabId={2}>
															<>
																<Otp
																	phone={userDetails}
																	timeLeft={timeLeft}
																	code={code}
																	setCode={setCode}
																	error={error}
																	apiCall={apiCall}
																	setTimeLeft={setTimeLeft}
																/>
															</>
														</TabPane>
														<TabPane tabId={3}>
															{badUser||newUser ?  (
																<>
																	<NewCustomer
																		cnicUploaded={cnicUploaded}
																		frontImageData={frontImageData}
																		frontImageSrc={frontImageSrc}
																		backImageData={backImageData}
																		backImageSrc={backImageSrc}
																		setFrontImageData={setFrontImageData}
																		setFrontImageSrc={setFrontImageSrc}
																		setBackImageData={setBackImageData}
																		setBackImageSrc={setBackImageSrc}
																		userDetails={userDetails}
																		setUserDetails={setUserDetails}
																		error={error}
																		userDetailsEdit={userDetailsEdit}
																		// setShowPopup={setShowPopup}
																		setShowPdfPopup={setShowPdfPopup}
																		showPdfPopup={showPdfPopup}
																	/>
																</>
															) : ""}
														</TabPane>
														<TabPane tabId={4}>
															<div className="row">
																<h4 className='error-message'>
																	{error}
																</h4>
															</div>
															<div className="row" style={{}}>
																<Col>
																	<div className="d-flex justify-content-between" >
																		<h4 className="font-style ">Payment Summary</h4>
																		<h4 className="font-style ">Total: {parseInt(orderDetails.orderTotal).toLocaleString("en-US")} PKR</h4>
																	</div>
																	<div className="row justify-content-cneter">

																		<PaymentSummary
																			orderDetails={orderDetails}
																			setOrderDetails={setOrderDetails}
																		/>
																	</div>
																</Col>
																<div className="mb-3">
																	<div className="d-flex mb-3">
																		<h4 className="font-style ">Select your preferred payment method</h4>
																	</div>

																	<PaymentRadioButton
																		setPaymentType={setPaymentType}
																	/>
																</div>
															</div>

															<div>
																<PaymentModal
																	showPopupError={showPopupError}
																	closeBtnError={closeBtnError}
																	error={error}
																	showPopup={showPopup}
																	closeBtn={closeBtn}
																	setLoading={setLoading}
																	payOrder={payOrder}
																	orderDetails={orderDetails}
																	showPdfPopup={showPdfPopup}
																	closePdfBtn={closePdfBtn}
																/>
															</div>
														</TabPane>
													</TabContent>
												</AvForm>
											</>
										)}
										{!loading ?
											<NavigationButton
												activeTab={activeTab}
												apiCall={apiCall}
												error={error}
											/> : ""
										}
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Footer />
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withRouter(Register);